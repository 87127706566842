import { DATE_RANGES_DAYS } from 'constants/product_tracker/tracker'
import React from 'react'
import { Translation } from 'react-i18next'
import takeRight from 'lodash/takeRight'
import compact from 'lodash/compact'
import without from 'lodash/without'
import map from 'lodash/map'
import round from 'lodash/round'
import max from 'lodash/max'

import { numberOfDaysXMonthsFromNow } from '../helpers/date'
import { median, mean } from '../helpers/math'
import { isValidValue } from '../helpers/values'
import * as BuildChartArrays from './build_chart_arrays'

const chartFormat = (dataArray, maxLength) => {
  return takeRight(dataArray, maxLength)
}

const cleanValues = array => {
  return without(array, undefined, null).map(val =>
    parseFloat(val.toString().replace(/res|est/, ''))
  )
}

export const calculateAverages = (prices, ranks, unitsSold) => {
  const rawMedianPrice = median(cleanValues(prices))
  const avgPrice = round(rawMedianPrice, 2)

  const rawAverageRank = mean(cleanValues(ranks))
  const avgRank = round(rawAverageRank)

  const mappedSales = unitsSold.map(d => d && d[1])
  const rawAverageSales = mean(cleanValues(mappedSales))
  const avgSales = round(rawAverageSales, 2)

  const avgRevenue =
    isValidValue(avgPrice) && isValidValue(avgSales)
      ? round(avgPrice * avgSales, 2)
      : null
  const avgMonthlySales = round(avgSales * 30)
  const avgMonthlyRevenue =
    isValidValue(avgPrice) && isValidValue(avgMonthlySales)
      ? round(avgPrice * avgMonthlySales, 2)
      : null

  return {
    avg_price: avgPrice,
    avg_rank: avgRank,
    avg_sales: avgSales,
    averageMonthlySales: avgMonthlySales,
    avg_revenue: avgRevenue,
    averageMonthlyRevenue: avgMonthlyRevenue
  }
}

const dateMessage = (unitsSoldArray, inventoryArray, rankArray, range) => {
  const daysOfSales = compact(map(unitsSoldArray, elm => elm[1])).length
  const daysOfInventory = compact(map(inventoryArray, elm => elm[1])).length
  const daysOfRank = compact(rankArray).length
  const daysOfData = max([daysOfSales, daysOfInventory, daysOfRank])

  if (daysOfData === 0) {
    return
  }

  if (daysOfData < 7 || daysOfData < range) {
    return (
      <Translation ns="services">
        {t => (
          <div>
            {t(
              'services:format_tracker_chart.dateHeader.days',
              'Only {{ count }} Day of Data Available',
              { count: daysOfData }
            )}
          </div>
        )}
      </Translation>
    )
  }
  return (
    <Translation ns="services">
      {t => (
        <div>
          {t(
            'services:format_tracker_chart.dateHeader.range',
            'Displaying {{ count }} Day of Data',
            { count: range }
          )}
        </div>
      )}
    </Translation>
  )
}

export const inventorySincePrevious = (series, index) => {
  const current = series[index]
  const previous = series[index - 1]
  const isValidPrevious = previous && previous[0] === 'ok'
  const delta = isValidPrevious ? current[1] - previous[1] : 0
  return { current, delta }
}

const buildChartData = (productId, dataArrays, message) => {
  const data = {}

  DATE_RANGES_DAYS.forEach(month => {
    const range = numberOfDaysXMonthsFromNow(month)

    const dates = chartFormat(dataArrays.datesArray, range)
    const inventory = chartFormat(dataArrays.inventoryArray, range)
    const price = chartFormat(dataArrays.priceArray, range)
    const rank = chartFormat(dataArrays.rankArray, range)
    const unitsSold = chartFormat(dataArrays.unitsSoldArray, range)
    const sellers = chartFormat(dataArrays.sellersArray, range)
    const averages = calculateAverages(price, rank, unitsSold)
    const sellerChanges = chartFormat(dataArrays.sellerChangeArray, range)
    const limitedInv = chartFormat(dataArrays.limitedInvArray, range)

    data[month] = {
      message,
      product_id: productId,
      date_message: dateMessage(unitsSold, inventory, rank, range),
      selected_days: range,
      averages,
      data: dates.map((d, i) => {
        const inventoryData = inventorySincePrevious(inventory, i)

        return {
          date: d,
          inventory: inventoryData.current,
          inventoryDelta: inventoryData.delta,
          dailySales: unitsSold[i],
          rank: rank[i],
          price: price[i],
          sellers: sellers[i],
          sellerChange: sellerChanges[i],
          limitedInv: limitedInv[i]
        }
      })
    }
  })
  return data
}

export const run = ({ id, startDate, message, scrapes }) => {
  const dataArrays = BuildChartArrays.run(startDate, scrapes)

  const chartData = buildChartData(id, dataArrays, message)

  return chartData
}
