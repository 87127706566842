import { getLocalData, setLocalData, USABILLA_LIVE_DATA } from 'helpers/storage'

export const setGetFeedbackCustomVariables = segmentData => {
  if (
    segmentData &&
    window.usabilla_live &&
    typeof window.usabilla_live === 'function'
  ) {
    const allowedKeys = [
      'email',
      'mwsKeysCount',
      'planName',
      'checkoutLocale',
      'languageApp',
      'createdAt',
      'planStatus',
      'planFrequency',
      'signInCount',
      'activePPCAccounts'
    ]

    // read variables from local storage
    const cachedUsabillaData =
      JSON.parse(getLocalData(USABILLA_LIVE_DATA)) || {}

    const customVariables = allowedKeys.reduce((o, key) => {
      if (key in segmentData) {
        Object.assign(o, { [key]: segmentData[key] })
      }
      return o
    }, {})

    // merge with cached variables
    const mergedVariables = {
      ...cachedUsabillaData,
      ...customVariables
    }

    // cache custom variables for usabilla live in local storage
    setLocalData(USABILLA_LIVE_DATA, JSON.stringify(mergedVariables))

    window.usabilla_live('data', {
      custom: mergedVariables
    })
  }
}
