import { isChinaDomain } from 'helpers/url'

export const daysFromNow = days => {
  const now = new Date()

  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + days)
}

export const monthsFromNow = months => {
  const now = new Date()

  return new Date(now.getFullYear(), now.getMonth() + months, now.getDate())
}

const oneMonthLater = () => monthsFromNow(1).toUTCString()

const oneDayLater = () => daysFromNow(1).toUTCString()

const isDeadObjectException = e => {
  return e instanceof TypeError && e.message === "can't access dead object"
}

const addCookieDomain =
  process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test'

const currentDomain = () => {
  return `junglescout.${isChinaDomain() ? 'cn' : 'com'}`
}

export const setCookie = (prop, value, opts = {}) => {
  const { domain, expires } = opts
  const expiresAt = expires || oneDayLater()
  let cookie = `${prop}=${value};expires=${expiresAt};path=/`

  if (domain) {
    cookie = `${cookie};domain=${domain}`
  }

  document.cookie = cookie
}

export const deleteCookieWithoutReload = prop => {
  const expiryDate = new Date(2000, 1, 1).toUTCString()
  document.cookie = `${prop}=;expires=${expiryDate};path=/`
}

export const getCookie = name => {
  try {
    const cookiePairs = document.cookie.split('; ')
    const filteredPairs = cookiePairs.filter(element =>
      element.startsWith(`${name}=`)
    )

    if (filteredPairs.length > 0) {
      return filteredPairs[0].split('=')[1]
    }

    return null
  } catch (e) {
    if (isDeadObjectException(e)) {
      return null
    }

    throw e
  }
}

// token: string
export const saveAuthToken = token => {
  const cookie = `${
    process.env.REACT_APP_AUTH_TOKEN_COOKIE
  }=${token};expires=${oneMonthLater()};path=/`

  document.cookie = addCookieDomain
    ? `${cookie};domain=${currentDomain()}`
    : cookie
}

export const deleteAuthToken = () => {
  const expiryDate = new Date(2000, 1, 1).toUTCString()
  const cookies = [
    `${process.env.REACT_APP_AUTH_TOKEN_COOKIE}=;expires=${expiryDate};path=/`,
    // STYXKEY_ is a prefix required for the marketing website's PHP server to read it.
    `STYXKEY_${process.env.REACT_APP_AUTH_TOKEN_COOKIE}=;expires=${expiryDate};path=/`
  ]

  cookies.forEach(cookie => {
    document.cookie = cookie
    if (addCookieDomain) {
      document.cookie = `${cookie};domain=members.${currentDomain()}`
      document.cookie = `${cookie};domain=.members.${currentDomain()}`
      document.cookie = `${cookie};domain=${currentDomain()}`
    }
  })
}

export const getAuthToken = () => {
  return getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)
}

export const parseAuthTokenJwt = () => {
  try {
    const token = getAuthToken()
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const hasCobaltAuthToken = () => {
  const token = parseAuthTokenJwt()
  return token?.app_type === 'cobalt'
}
