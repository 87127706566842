import { billingFrequencyFormatter } from 'helpers/billing'

export const sendGoogleEvent = data => {
  if (data && process.env.NODE_ENV === 'production') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }
}

const sendMembershipSignUpEvent = ({ membershipInfo, email }) => {
  const trialDays = membershipInfo.trial_period_days
  const price = getMembersipConversionValue(membershipInfo)
  // amount_off is not returned by the API for Base Plans
  const amountOff = membershipInfo?.amount_off || 0
  const monthly =
    membershipInfo.billing_frequency === 'monthly' &&
    membershipInfo.charge_frequency === 1

  if (price) {
    sendGoogleEvent({
      event: 'signup',
      product: getGAMembershipPlanType(membershipInfo),
      trial: trialDays,
      conversionValue: price,
      monthly,
      email,
      ecommerce: {
        currencyCode: membershipInfo.stripe_plan?.currency,
        purchase: {
          actionField: {
            id: membershipInfo.transactionId,
            revenue: price
          },
          products: [
            {
              name: membershipInfo.unique_name,
              category: `Web App/${membershipInfo.payment_plan}/${membershipInfo.normalized_name}`,
              price,
              quantity: 1
            }
          ]
        }
      },
      ecommerceGA4: {
        currency: membershipInfo.stripe_plan?.currency,
        transaction_id: membershipInfo.transactionId,
        value: price,
        items: [
          {
            item_id: membershipInfo.unique_name,
            item_name: `Web App/${membershipInfo.payment_plan}/${membershipInfo.normalized_name}`,
            currency: membershipInfo.stripe_plan?.currency,
            item_category: 'Web App',
            item_category2: membershipInfo.payment_plan,
            // The price and discount returned from the API is an integer and the price should be a float
            price: (membershipInfo.price + amountOff) / 100,
            discount: amountOff / 100,
            quantity: 1,
            index: 0
          }
        ]
      }
    })
  }
}

export const updateGoogleDataLayer = payload => {
  if (process.env.NODE_ENV !== 'production') return

  sendMembershipSignUpEvent(payload)
}

export const setUserInGoogleAnalytics = ({
  user: { email, id, stripe_customer_id },
  membershipInfo
}) => {
  if (process.env.NODE_ENV !== 'production') return

  if (id) {
    sendGoogleEvent({
      event: 'setUserId',
      userId: id
    })
    sendGoogleEvent({
      event: 'setCustomerId',
      customerId: stripe_customer_id
    })
    sendGoogleEvent({
      event: 'setUserEmail',
      userEmail: email
    })
  }

  sendGoogleEvent({
    event: 'setMembershipStatus',
    membershipStatus: getGAMembershipStatus(membershipInfo)
  })

  sendGoogleEvent({
    event: 'setMembershipType',
    membershipType: getGAMembershipPlanType(membershipInfo)
  })
}

export const getGAMembershipStatus = ({ membershipStatus }) => {
  switch (membershipStatus) {
    case 5:
      return 'Paused'
    case 99: // no membership
      return 'Inactive'
    default:
      return 'Active'
  }
}

const getMembersipConversionValue = membershipInfo => {
  return membershipInfo.price / 100
}

export const getGAMembershipPlanType = ({
  hasActiveMembership,
  renewable,
  group,
  normalized_name,
  billing_frequency,
  charge_frequency
}) => {
  if (!hasActiveMembership) return 'None'

  let interval

  if (!renewable) {
    interval = 'Single Purchase'
  } else if (charge_frequency !== 1) {
    interval = `${charge_frequency} ${billingFrequencyFormatter(
      billing_frequency,
      'short'
    )}`
  } else {
    interval = billingFrequencyFormatter(billing_frequency)
  }

  // Normalized name added as a redundancy for legacy plans
  return `Web App ${group || normalized_name} ${interval}`
}
