export const mountGoogleRecaptcha = action => {
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY

  const script = document.createElement('script')
  script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`
  script.type = 'text/javascript'
  script.async = true
  script.onload = () => {
    window.grecaptcha.ready(async () => {
      const captchaResult = await executeRecaptcha(action)
      return captchaResult
    })
  }
  document.body.appendChild(script)
}

export const executeRecaptcha = async action => {
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY

  if (window.grecaptcha) {
    const token = await window.grecaptcha.execute(siteKey, { action })

    return token
  }
}
